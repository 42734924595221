
.search-close-hide{
    display: none;
}



@media (min-width:320px) and (max-width: 400px) {
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
       .fa-solid{
        margin: 0px ;
        padding: 0px;
       }
       .creative h1{
        font-size: 50px;
       }

    .blog_imner{
        text-align: center;
    }
    .navbar-brand img{
        margin-left: 13px;
    }
   

}
@media (min-width:401px) and (max-width: 499px){
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
      
    .navbar-brand img{
        margin-left: 13px;
    }
   
    
}
@media (min-width:500px) and (max-width: 575px){
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
       .creative h1{
           font-size: 52px;
       }
      
}
@media (min-width:576px) and (max-width: 767px) {
    #collapsibleNavbar{
        background: rgba(56,56,56,0.95);
        border-radius: 10px;
       }
       .creative h1{
           font-size: 80px;
       }
       .counter_inner{
        height: 470px;
    }
    .counter-box{
        padding: 4px 0;
    }
    .creative h2{
        font-size: 54px;
    }
    
    
    
}
@media (min-width:992px) and (max-width: 1200px){
    
}



    @media (min-width: 1200px)
    {
        .container{
            width: 1170px;
        }
    }

@media only screen and (max-width: 375px){
    /* .header-login.button-item.bordered{
        display: block;
    } */
    

    .tinvwl-to-right.look_in  button{
        width: 47% !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        margin-right: 5px !important;
    }

    .mobile-filter{
        padding: 10px 0px;
    }
    .filter-products-sidebar{
        opacity: 1 !important;
        visibility: inherit !important;
        transform: translate(0px, 0px) !important;
    }

}

@media only screen and (max-width: 730px){
    .search-close-hide{
        display: block !important;
    }

    .customer-sign{
        width: fit-content !important;
    }
    
    

    .site-scroll.ps.ps--active-y.ps--scrolling-y{
        overflow: auto !important;
    }

    .before-shop-loop .product-filter.woocommerce-ordering::before{
        content: '' !important;
    }
}

#all-categories{
    height: 500px;
    overflow-y: auto;
}

.item-search{
    width: fit-content;
    padding: 10px;
    float: right;
}

