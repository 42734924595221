.customer-sign{
    border-radius: 5px;
}

.category_parent > input{
    margin-right: .9375rem;
}
.category_parent > label{
    display: inline-flex !important;
}
.product-unavailable{
    color: red;
    font-size: larger;
    font-weight: 600;
    text-align: center;
    margin-top: 2vh;
}
.product-cat-pagination > li{
    width: fit-content ;
    padding-right:10px !important;
}
.padination-disabled{
    pointer-events:none;
    opacity:0.5;
}

.button.remove-all{
    color: white;
}

.disabled{
    pointer-events: none;
    cursor: default;
    text-decoration: none !important;
    color: black !important;
    opacity: 0.6;
}
.cst-50-w{
    float: left;
    /* width: 50% !important; */
    flex: 0 0 16.666667% !important;
    max-width: 50% !important;
}

.elementor-1685 .elementor-element.elementor-element-a65fc9f {
    margin-top: 40px;
    margin-bottom: 10px;
}

.elementor-1685 .elementor-element.elementor-element-4cfc479 {
    text-align: center;
}
.elementor-1685 .elementor-element.elementor-element-54306c4 {
    text-align: center;
}

.elementor-element-c431c4d{
    margin-top:15px;
}

.add-trans{
    transition:  transform 1200ms cubic-bezier(0.48,0,0.12,1) 0s !important;
}

.btn-small{
    height: 25px;
    width: 76px;
    padding: 0 10px;
    float: right;
}


/* .elementor-3 .elementor-element.elementor-element-9cf14cd .elementor-heading-title {
    font-weight: 600;
}


.elementor-3 .elementor-element.elementor-element-9c2161e .elementor-heading-title {
    font-weight: 600;
} */

.privacy-policy h5{
    font-weight: 600;
}

.privacy-policy h2{
    font-weight: 600;
    text-align: center;
}

.privacy-policy .sub-text{
    margin-top: 20px;
}

.privacy-policy .sub-text-p{
    margin-top: 10px;
}

.privacy-policy{
    margin-top: 30px;
}

.privacy-policy p{
    font-size: 16px;
    line-height: 24px;
}

p.elementor-heading-title.elementor-size-default{
    margin-top:10px;
}

.brand-expand{
    max-height: 285px;
    overflow: auto;
}

.brand-collapse{
    max-height: 285px;
    overflow: hidden;
}

.brand-see-more{
    color: #2bbef9;
}

.brand-see-more:hover{
    cursor: pointer;
    text-decoration: underline;
    margin-top: -50px;
}

.bdg-success{
    color: #fff !important;
    background-color: #28a745 !important;
}
.show-password{
    cursor:pointer;
    float: right;
    position: relative;
    margin-top: -33px;
    margin-right: 5px;
    font-size:20px;
}