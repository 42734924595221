.ps-theme-wpc {
    -ms-touch-action: none;
    touch-action: none;
    overflow: hidden !important;
    -ms-overflow-style: none;
}

@supports (-ms-overflow-style: none) {
    .ps-theme-wpc {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps-theme-wpc {
        overflow: auto !important;
    }
}

.ps-theme-wpc.ps-active-x > .ps-scrollbar-x-rail,
.ps-theme-wpc.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent;
}

.ps-theme-wpc.ps-in-scrolling {
    pointer-events: none;
}

.ps-theme-wpc.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
    background-color: rgba(0, 0, 0, .1) !important;
}

.ps-theme-wpc.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: rgba(0, 0, 0, 1);
}

.ps-theme-wpc.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
    background-color: rgba(0, 0, 0, .1) !important;
}

.ps-theme-wpc.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: rgba(0, 0, 0, 1);
    width: 2px;
    border-radius: 1px;
}

.ps-theme-wpc > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    bottom: 0;
    /* there must be 'bottom' for ps-scrollbar-x-rail */
    height: 2px;
    border-radius: 1px;
}

.ps-theme-wpc > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    position: absolute;
    /* please don't change 'position' */
    background-color: rgba(0, 0, 0, 1);
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    bottom: 0;
    /* there must be 'bottom' for ps-scrollbar-x */
    height: 2px !important;
    border-radius: 1px !important;
}

.ps-theme-wpc > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x, .ps-theme-wpc > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
    height: 2px;
    border-radius: 1px;
}

.ps-theme-wpc > .ps-scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y-rail */
    width: 2px;
    border-radius: 1px;
}

.ps-theme-wpc > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    position: absolute;
    /* please don't change 'position' */
    background-color: rgba(0, 0, 0, 1);
    /* border-radius: 0; */
    transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
    right: 0;
    /* there must be 'right' for ps-scrollbar-y */
    width: 2px !important;
    border-radius: 1px !important;
}

.ps-theme-wpc > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-theme-wpc > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
    width: 2px;
    border-radius: 1px;
}

.ps-theme-wpc:hover.ps-in-scrolling {
    pointer-events: none;
}

.ps-theme-wpc:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
    background-color: rgba(0, 0, 0, 1);
}

.ps-theme-wpc:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
    background-color: rgba(0, 0, 0, 1);
    height: 2px;
    border-radius: 1px;
}

.ps-theme-wpc:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
    background-color: rgba(0, 0, 0, 1);
}

.ps-theme-wpc:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
    background-color: rgba(0, 0, 0, 1);
    width: 2px;
    border-radius: 1px;
}

.ps-theme-wpc:hover > .ps-scrollbar-x-rail:hover {
    background-color: rgba(0, 0, 0, .1);
    opacity: 1;
}

.ps-theme-wpc:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
    background-color: rgba(0, 0, 0, 1);
}

.ps-theme-wpc:hover > .ps-scrollbar-y-rail:hover {
    background-color: rgba(0, 0, 0, .1);
    opacity: 1;
}

.ps-theme-wpc:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
    background-color: rgba(0, 0, 0, 1);
}
