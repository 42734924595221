.klb-free-progress-bar{
	padding: 20px;
    border: 1px solid var(--color-border);
    margin-bottom: 20px;
	border-radius: var(--size-border-radius);
	width: 100%;
}

.klb-free-progress-bar .free-shipping-notice{
    color: var(--color-text);
    padding-bottom: 0.625rem;
    font-size: .8125rem;
}

.klb-free-progress-bar .free-shipping-notice span {
    color: var(--color-danger);
	font-weight: bold;
}
.klb-free-progress-bar .free-shipping-notice strong {
    color: #000;
	font-weight: 500;
}
.woo-mini-cart .klb-free-progress-bar  .free-shipping-notice{
	padding-top: 1.25rem;
    font-size: .800rem;
    font-weight: 400;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.woo-mini-cart .klb-free-progress-bar {
	padding: 0;
    border: none;
    margin-bottom: 0;
}

.klb-progress-bar {
	position: relative;
	width: 100%;
	height: 6px;
	background-color: #f2f3f5;
	border-radius: 0.625rem;
}

.klb-progress-bar span {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 0.625rem;
	background: var(--color-danger);
}

.cart-dropdown .klb-free-progress-bar {
	display: none;
}