/**
 * TI WooCommerce Wishlist Plugin - Allow your store guests and customers to add products to Wishlist.  Add Wishlist functionality to your store for free.
 * @version 2.0.5
 * @link https://wordpress.org/plugins/ti-woocommerce-wishlist/
 */
@font-face{font-family:"tinvwl-webfont";font-display:block;src:url("");src:url("") format("embedded-opentype"),url("") format("woff2"),url("") format("woff"),url("") format("truetype"),url("") format("svg");font-weight:normal;font-style:normal}
.ftinvwl{font-family:"tinvwl-webfont" !important;speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.ftinvwl-twitter:before{content:"\f099"}
.ftinvwl-facebook:before{content:"\f09a" !important}
.ftinvwl-facebook-f:before{content:"\f09a" !important}
.ftinvwl-google:before{content:"\f0d5" !important}
.ftinvwl-email:before{content:"\f0e0" !important}
.ftinvwl-pinterest:before{content:"\f231" !important}
.ftinvwl-whatsapp:before{content:"\f232" !important}
.ftinvwl-clipboard:before{content:"\e911" !important}
.ftinvwl-star:before{content:"\e912" !important}
.ftinvwl-shopping-cart:before{content:"\e913" !important}
.ftinvwl-magic:before{content:"\e914" !important}
.ftinvwl-info:before{content:"\e915" !important}
.ftinvwl-graduation-cap:before{content:"\e918" !important}
.ftinvwl-floppy-o:before{content:"\e919" !important}
.ftinvwl-eyedropper:before{content:"\e91a" !important}
.ftinvwl-exclamation-triangle:before{content:"\e91b" !important}
.ftinvwl-check:before{content:"\e91e" !important}
.ftinvwl-arrow-left:before{content:"\e91f" !important}
.ftinvwl-wrench:before{content:"\e920" !important}
.ftinvwl-chevron-down:before{content:"\e900" !important}
.ftinvwl-chevron-right:before{content:"\e901" !important}
.ftinvwl-chevron-left:before{content:"\e902" !important}
.ftinvwl-chevron-up:before{content:"\e903" !important}
.ftinvwl-cancel:before{content:"\e904" !important}
.ftinvwl-times:before{content:"\292C" !important}
.ftinvwl-heart-plus:before{content:"\e906" !important}
.ftinvwl-heart-mark-right:before{content:"\e907" !important}
.ftinvwl-heart2:before{content:"\e908" !important}
.ftinvwl-heart-o:before{content:"\e909" !important}
.ftinvwl-heart-mark-left:before{content:"\e90a" !important}
.ftinvwl-heart-mail:before{content:"\e90b" !important}
.ftinvwl-heart-tinv:before{content:"\e90c" !important}
.ftinvwl-key:before{content:"\e90d" !important}
.ftinvwl-lock:before{content:"\e90e" !important}
.ftinvwl-hearts:before{content:"\e90f" !important}
.ftinvwl-user:before{content:"\e910" !important}
.ftinvwl-pulse.ftinvwl-animated::before{-webkit-animation:ftinvwl-pulse 2s linear infinite;animation:ftinvwl-pulse 2s linear infinite}
@-webkit-keyframes ftinvwl-pulse{0%{-webkit-transform:scale(1.1);transform:scale(1.1)}
50%{-webkit-transform:scale(0.8);transform:scale(0.8)}
100%{-webkit-transform:scale(1.1);transform:scale(1.1)}
}@keyframes ftinvwl-pulse{0%{-webkit-transform:scale(1.1);transform:scale(1.1)}
50%{-webkit-transform:scale(0.8);transform:scale(0.8)}
100%{-webkit-transform:scale(1.1);transform:scale(1.1)}
}