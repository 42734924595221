/**
 * TI WooCommerce Wishlist Plugin - Allow your store guests and customers to add products to Wishlist.  Add Wishlist functionality to your store for free.
 * @version 2.0.5
 * @link https://wordpress.org/plugins/ti-woocommerce-wishlist/
 */
.tinv-wishlist form,.tinv-wishlist p:last-child,.tinv-wishlist table{margin-bottom:0}
.tinv-wishlist *{-webkit-box-sizing:border-box;box-sizing:border-box}
.tinvwl-wishlist :after,.tinvwl-wishlist :before{-webkit-box-sizing:border-box;box-sizing:border-box}
.tinv-wishlist select{width:140px}
.tinv-wishlist ul:not(.woocommerce-error){list-style:none;margin:0;padding:0}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt:before,.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt:before{margin-top:-10px;margin-left:-10px}
.woocommerce.tinv-wishlist #respond input#submit.alt,.woocommerce.tinv-wishlist a.button.alt,.woocommerce.tinv-wishlist button.button.alt,.woocommerce.tinv-wishlist input.button.alt{text-align:center}
.tinv-wishlist .button i,.tinv-wishlist .navigation-button a i{margin-right:6px}
.tinv-wishlist input[type=button] i,.tinv-wishlist input[type=reset] i,.tinv-wishlist input[type=submit] i{margin-right:6px}
.tinv-wishlist a.tinv-close-modal i{margin-right:0}
a.wishlist_products_counter{text-decoration:none}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart:before,.woocommerce ul.products li.product a.tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart:before,.woocommerce-page ul.products li.product a.tinvwl-button.tinvwl-icon-heart.tinvwl_add_to_wishlist_button:before,a.wishlist_products_counter.top_wishlist-heart:before,span.wishlist_products_counter.top_wishlist-heart:before,a.sidr-class-wishlist_products_counter.sidr-class-top_wishlist-heart:before{content:"\2661"; font-weight:bolder;display:inline-block;font-family:tinvwl-webfont !important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:20px;vertical-align:sub;margin-right:5px}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus:before,.woocommerce ul.products li.product a.tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus:before,.woocommerce-page ul.products li.product a.tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus:before,a.wishlist_products_counter.top_wishlist-heart-plus:before,span.wishlist_products_counter.top_wishlist-heart-plus:before,a.sidr-class-wishlist_products_counter.sidr-class-top_wishlist-heart-plus:before{content:"\e906";display:inline-block;font-family:tinvwl-webfont !important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:20px;vertical-align:sub;margin-right:5px}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt{position:relative;width:18px;height:18px;vertical-align:sub}
a.wishlist_products_counter.top_wishlist-heart-plus.no-txt,span.wishlist_products_counter.top_wishlist-heart-plus.no-txt{position:relative;width:18px;height:18px;vertical-align:sub}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt:before,.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt:before{position:absolute;top:50%;left:50%;margin-right:0}
.tinv-wishlist .tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.no-txt,.tinv-wishlist .tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart.no-txt{padding-left:1em;vertical-align:bottom}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart.tinvwl-product-in-list:before{content:"\2665"}
.woocommerce ul.products li.product a.tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart.tinvwl-product-in-list:before,.woocommerce-page ul.products li.product a.tinvwl-button.tinvwl-icon-heart.tinvwl_add_to_wishlist_button.tinvwl-product-in-list:before,a.wishlist_products_counter.top_wishlist-heart.wishlist-counter-with-products:before,span.wishlist_products_counter.top_wishlist-heart.wishlist-counter-with-products:before{content:"\e908"}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.tinvwl-product-in-list:before,.woocommerce ul.products li.product a.tinvwl-button.tinvwl_add_to_wishlist_button.tinvwl-icon-heart-plus.tinvwl-product-in-list:before,.woocommerce-page ul.products li.product a.tinvwl-button.tinvwl-icon-heart-plus.tinvwl_add_to_wishlist_button.tinvwl-product-in-list:before,a.wishlist_products_counter.top_wishlist-heart-plus.wishlist-counter-with-products:before,span.wishlist_products_counter.top_wishlist-heart-plus.wishlist-counter-with-products:before{content:"\e907"}
.tinv-wishlist .tinvwl_add_to_wishlist_button.icon-white:before,a.wishlist_products_counter.top_wishlist-white:before,span.wishlist_products_counter.top_wishlist-white:before{color:#FFF}
.tinv-wishlist .tinvwl_add_to_wishlist_button.icon-black:before,a.wishlist_products_counter.top_wishlist-black:before,span.wishlist_products_counter.top_wishlist-black:before{color:#000}
.tinv-wishlist.tinvwl-before-add-to-cart .tinvwl_add_to_wishlist_button{margin-bottom:15px}
.tinv-wishlist.tinvwl-after-add-to-cart .tinvwl_add_to_wishlist_button{margin-top:15px}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-custom.no-txt{width:18px;height:18px;line-height:1}
.wishlist-popup .tinv-wishlist .tinvwl_add_to_wishlist_button{margin:0}
.wishlist-popup .tinv-wishlist .tinvwl_add_to_wishlist_button::before{display:none}
a.wishlist_products_counter.top_wishlist-custom.no-txt,span.wishlist_products_counter.top_wishlist-custom.no-txt{width:18px;height:18px;line-height:1}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-custom img,a.wishlist_products_counter.top_wishlist-custom img,span.wishlist_products_counter.top_wishlist-custom img{display:inline-block !important;vertical-align:baseline;width:auto !important;max-width:16px;max-height:16px;margin:0 6px 0 0 !important}
.tinv-wishlist .tinvwl_add_to_wishlist_button.tinvwl-icon-custom.no-txt img,a.wishlist_products_counter.top_wishlist-custom.no-txt img,span.wishlist_products_counter.top_wishlist-custom.no-txt img{margin-right:0 !important}
.single-product div.product form.cart .tinvwl-button.tinvwl_add_to_wishlist_button,div.product form.cart .tinvwl_add_to_wishlist_button{float:none}
ul.products li.product .tinvwl_add_to_wishlist_button{margin-top:1em}
.tinvwl_add_to_wishlist_button{display:inline-block;cursor:pointer;-webkit-transition:opacity 1s;transition:opacity 1s;opacity:1;visibility:visible;position:relative}
.tinv-wishlist.woocommerce .stock.in-stock:before{content:none}
.tinv-wraper.tinv-wishlist{font-size:100%}
.tinv-wraper.tinv-wishlist.tinvwl-above_thumb-add-to-cart{position:absolute;z-index:10;margin:0;top:10px;left:10px}
.tinv-wraper.tinv-wishlist.tinvwl-above_thumb-add-to-cart a.tinvwl_add_to_wishlist_button{margin-top:0}
.tinv-create-list li input[type=radio]{margin-right:10px}
.tinv-create-list li+li{margin-top:15px}
.tinv-create-list .tinvwl-input-group+ul{margin-top:25px}
.tinv-search-list{margin-bottom:36px}
.tinv-wishlist .tinv-header{margin-bottom:30px}
.tinv-wishlist table{position:relative;table-layout:auto;margin-bottom:30px}
.tinv-overlay{top:0;left:0;position:fixed;-webkit-transition:opacity .3s ease,visibility .3s ease;transition:opacity .3s ease,visibility .3s ease}
.tinv-wishlist .tinv-modal{top:0;left:0;position:fixed;-webkit-transition:opacity .3s ease,visibility .3s ease;transition:opacity .3s ease,visibility .3s ease}
.tinv-wishlist table.tinvwl-table-manage-list{margin-bottom:27px;width:100%}
.tinv-wishlist table input[type=checkbox]{margin-right:0}
.tinv-wishlist table td,.tinv-wishlist table th{padding:1em;vertical-align:middle}
.tinv-wishlist .tinvwl-table-manage-list .product-cb{width:35px;text-align:center}
.tinv-wishlist .tinvwl-table-manage-list .product-remove{width:35px;text-align:center;padding:1em .5em}
.tinv-wishlist .product-remove button{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-ms-flex-line-pack:center;align-content:center;margin:0 auto;width:27px;height:27px;border-radius:50%;padding:0;-webkit-box-shadow:none;box-shadow:none;border:0;background-color:#f7f7f7;color:#000;font-size:20px;padding-left:1px;padding-top:3px;min-height:0}
.tinv-wishlist .product-thumbnail{min-width:100px;max-width:100%;width:100px}
.tinv-wishlist .product-thumbnail .wp-post-image{margin-bottom:0}
.tinv-wishlist td.product-name a+.variation{margin-top:2px}
.tinv-wishlist .product-stock i{margin-right:15px}
.tinv-wishlist .product-stock p{display:table}
.tinv-wishlist .product-stock p.stock::before{display:none}
.tinv-wishlist .product-stock span{display:table-cell;vertical-align:middle}
.tinv-wishlist .product-action>.button>i,.tinv-wishlist .tinvwl-table-manage-list .product-action button[name=tinvwl-remove],.tinv-wishlist table thead th .tinvwl-mobile{display:none}
.tinv-wishlist.woocommerce .product-quantity{width:80px;text-align:center}
.tinv-wishlist.woocommerce table .quantity .qty{max-width:100%;width:62px;text-align:left}
.tinv-wishlist .product-action{width:135px;text-align:center}
.tinv-wishlist .product-action .button{width:100%}
.tinv-wishlist .product-action .tinvwl_move_product_button{margin-top:5px}
.tinv-wishlist .tinvwl-table-manage-list .product-action>button[name=tinvwl-remove]>i{margin-right:0}
.tinv-wishlist .wishlist-cb{width:33px;text-align:center}
.tinv-wishlist .wishlist-name .tinvwl-rename-input input{width:100%}
.tinv-wishlist .wishlist-name .tinvwl-rename-button{float:right}
.tinv-wishlist .wishlist-name .tinvwl-rename-button>i{margin-right:10px}
.tinv-wishlist .wishlist-privacy,.tinv-wishlist table:not(.tinvwl-public) .wishlist-date{width:18%}
.tinv-wishlist .wishlist-privacy select{width:100%}
.tinv-wishlist .wishlist-name{width:45%}
.tinv-wishlist .wishlist-action{width:120px;text-align:center}
.tinv-wishlist .wishlist-action button[value=manage_remove]>i{display:none;margin-right:0}
.tinv-wishlist tfoot .tinvwl-to-right .tinv-create-list{display:inline-block;vertical-align:middle}
.tinv-wishlist tfoot .tinvwl-to-right .tinv-create-list>a.button{margin-right:0}
.tinv-wishlist tfoot .tinvwl-to-left+.tinvwl-to-right{margin-top:-10px}
.tinv-wishlist tfoot .tinvwl-to-left:not(:empty){float:left;width:35%;margin-right:2%}
.tinv-wishlist tfoot .tinvwl-to-right{float:left;width:100%;text-align:right}
.tinv-wishlist tfoot .tinvwl-to-right>*{margin:10px 0 0;vertical-align:middle}
.tinv-wishlist tfoot .tinvwl-to-right>*+*{margin-left:10px}
.tinv-wishlist tfoot .tinvwl-to-left:empty+.tinvwl-to-right{width:100%}
.tinv-wishlist .social-buttons+.tinv-wishlist-clear+.navigation-button{margin-top:16px}
.tinv-wishlist .navigation-button{margin-top:-10px}
.tinv-wishlist .navigation-button>li{float:left}
.tinv-wishlist .navigation-button>li>.tinv-create-list>a,.tinv-wishlist .navigation-button>li>a{margin-right:30px;margin-top:10px;display:inline-block}
.tinv-wishlist .navigation-button>li>.tinv-create-list>a.button,.tinv-wishlist .navigation-button>li>a.button{margin-right:10px}
.tinv-wishlist .navigation-button>li:last-child>.tinv-create-list>a,.tinv-wishlist .navigation-button>li:last-child>a{margin-right:0}
.tinv-wishlist .social-buttons{text-align:right}
.tinv-wishlist .social-buttons>span,.tinv-wishlist .social-buttons>ul{display:inline-block;vertical-align:middle}
.tinv-wishlist .social-buttons>span{margin-right:27px}
.tinv-wishlist .social-buttons li{float:left;margin:0 5px 0 0;list-style:none}
.tinv-wishlist .social-buttons li:last-child{margin-right:0}
.tinv-wishlist .social-buttons li a.social{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-line-pack:center;align-content:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:2em;height:2em;border-radius:50%;text-align:center;-webkit-box-shadow:none;box-shadow:none;border:0;font-size:20px;text-decoration:none;text-transform:none !important}
.tinv-wishlist .social-buttons li a.social.white{color:#fff}
.tinv-wishlist .social-buttons li a.social.dark{color:#000}
.tinv-wishlist .social-buttons li a.social i{line-height:2em}
.tinv-wishlist .navigation-button .tinv-create-list>a.tinvwl-no-icon>i,.tinv-wishlist .navigation-button li>a.tinvwl-no-icon>i{display:none}
.tinv-wishlist .tinv-lists-nav{margin-top:35px;margin-bottom:35px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%}
.tinv-wishlist .tinv-lists-nav>*{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-ms-flex-preferred-size:100%;flex-basis:100%;-webkit-box-flex:1;-ms-flex:1;flex:1;text-align:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
.tinv-wishlist .tinv-lists-nav>span{font-weight:700}
.tinv-wishlist .tinv-next{display:inline-block;float:left}
.tinv-wishlist .tinv-prev{display:inline-block;float:left;margin-right:35px !important}
.tinv-wishlist .tinv-prev i{margin-left:0;margin-right:18px}
.tinv-wishlist .tinv-next i{margin-left:18px;margin-right:0}
.tinv-overlay{width:100%;height:100%;visibility:hidden;opacity:0;background:#191919}
.tinv-modal.tinv-modal-open .tinv-overlay{visibility:visible;opacity:.5}
.admin-bar .tinv-wishlist .tinv-modal{padding-top:32px !important}
.tinv-wishlist .tinv-modal{overflow-y:auto;overflow-x:hidden;width:0;height:0;z-index:9999;outline:0 !important;-webkit-backface-visibility:hidden;visibility:hidden;opacity:0;text-align:left}
.tinv-wishlist .tinv-modal .tinv-modal-inner{position:relative;margin:0 auto;background-color:#fff;max-width:360px;padding:40px}
.tinv-wishlist .tinv-modal.tinv-modal-open{visibility:visible;opacity:1;width:100%;height:100%}
.tinv-wishlist .tinv-modal .tinv-close-modal{display:inline-block;position:absolute;top:17px;right:14px;width:26px;height:26px;line-height:26px;font-size:12px;text-align:center;border-radius:50%;border-bottom:0;-webkit-box-shadow:none !important;box-shadow:none !important;background-color:#ebe9eb}
.tinv-wishlist .tinv-modal .icon_big_heart_check,.tinv-wishlist .tinv-modal .icon_big_times,.tinv-wishlist .tinv-modal img{display:block;margin:0 auto;margin-bottom:25px;opacity:1 !important}
.tinv-wishlist .tinv-modal ul{overflow:visible;list-style:disc;margin:10px 0 0 20px}
.tinv-wishlist .tinv-modal li{list-style:disc !important}
.tinv-wishlist .tinv-create-list .tinv-modal ul,.tinv-wishlist.tinv-create-list form ul{list-style:none !important;margin:25px 0 0}
.tinv-wishlist .tinv-create-list .tinv-modal li,.tinv-wishlist.tinv-create-list form li{list-style:none !important}
.tinv-wishlist .tinv-modal .already-in{margin-bottom:35px}
.tinv-wishlist .tinv-modal .delete-notification{margin-bottom:25px}
.tinv-wishlist .tinv-modal .already-in ul{overflow:visible;margin:12px 0 27px 17px}
.tinv-wishlist .tinv-modal select{width:100%}
.tinv-wishlist .tinv-modal button+.button,.tinv-wishlist .tinv-modal button+button{margin-top:12px;width:100%}
.tinv-wishlist .tinv-modal input+button{margin-top:12px;width:100%}
.tinv-wishlist .tinv-modal label+button,.tinv-wishlist .tinv-modal label+input{margin-top:12px;width:100%}
.tinv-wishlist .tinv-modal select+button,.tinv-wishlist .tinv-modal select+input{margin-top:12px;width:100%}
.tinv-wishlist .tinv-modal .already-in+label{display:block;margin-top:6px}
.tinv-wishlist .tinv-modal label select{margin-top:8px}
.tinv-wishlist .tinv-modal .delete-notification+button{width:100%}
.tinv-wishlist .tinvwl_added_to_wishlist,.tinv-wishlist .tinvwl_created_wishlist{text-align:center}
.tinv-wishlist .tinvwl_added_to_wishlist .tinv-txt{margin-bottom:25px}
.tinv-wishlist .tinvwl_created_wishlist .tinv-txt{margin-bottom:25px}
.tinv-wishlist .tinvwl_created_wishlist button{margin-top:0;margin-bottom:10px}
.woocommerce .tinv-wishlist .tinvwl_added_to_wishlist.tinv-modal button.button{margin-top:0;margin-bottom:10px}
.tinv-wishlist .tinv-modal .tinvwl-buttons-group{margin-top:20px}
.tinv-wishlist .tinvwl-buttons-group button{width:100%}
.tinv-wishlist .tinvwl-buttons-group button+button{margin-top:7px}
.tinv-wishlist .tinvwl-buttons-group button i{position:relative}
.tinv-wishlist .tinvwl-buttons-group button i.ftinvwl-heart-o,.tinv-wishlist .tinvwl-buttons-group button i.ftinvwl-key,.tinv-wishlist .tinvwl-buttons-group button i.ftinvwl-times{font-size:20px;top:0;vertical-align:initial}
.tinv-wishlist .tinvwl-buttons-group button i.ftinvwl-heart-o::before,.tinv-wishlist .tinvwl-buttons-group button i.ftinvwl-key::before,.tinv-wishlist .tinvwl-buttons-group button i.ftinvwl-times::before{position:relative;top:3px}
.tinv-wishlist .tinvwl-buttons-group+button{width:100%;margin-top:7px}
.tinv-wishlist .tinv-modal h2{text-align:center;margin:0 0 35px}
.tinv-wishlist .tinv-create-list .tinv-modal-inner{max-width:778px;padding:30px}
.tinv-wishlist .tinvwl-has-error:not(.tinvwl-input-group),.tinv-wishlist .tinvwl-input-group.tinvwl-has-error .form-control{border:2px solid red}
.tinv-wishlist .tinvwl-has-error:not(.tinvwl-input-group){border:2px solid red}
.tinv-wishlist .tinvwl-has-error+.tinvwl-error{padding:5px 0 0 5px;color:red}
.tinvwl-tooltip{display:none}
.tinvwl-input-group{position:relative;display:table;border-collapse:separate}
.tinvwl-input-group .form-control{position:relative;z-index:1;float:left;height:38px;width:100%;margin:0}
.tinvwl-input-group .form-control+.tinvwl-input-group-btn{padding-left:15px}
.tinv-wishlist .tinvwl-to-left .tinvwl-input-group .form-control+.tinvwl-input-group-btn{padding-left:10px}
.tinv-wishlist .tinv-search-form .tinvwl-input-group .form-control+.tinvwl-input-group-btn{padding-left:9px}
.tinvwl-input-group .form-control,.tinvwl-input-group-addon,.tinvwl-input-group-btn{display:table-cell}
.tinvwl-input-group-addon,.tinvwl-input-group-btn{width:1%;white-space:nowrap;vertical-align:top}
.tinv-table{display:table;width:100%;height:100%}
.tinv-cell{display:table-cell;vertical-align:middle}
.tinv-wishlist .tinv-wishlist-clear{visibility:visible;width:auto;height:auto}
.tinv-wishlist-clear:before{content:"";display:table}
.tinv-wishlist-clear:after{content:"";display:table;clear:both}
.icon_big_heart_check{display:inline-block;width:46px;height:46px;font-family:tinvwl-webfont !important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:60px}
.icon_big_times{display:inline-block;width:46px;height:46px;font-family:tinvwl-webfont !important;speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:60px}
.icon_big_times::before{content:"\e904";top:-7px;left:-7px;position:relative}
.icon_big_heart_check::before{content:"\e90a";top:-7px;left:-7px;position:relative}
.tinvwl_add_to_wishlist_button.tinvwl-button.disabled-add-wishlist,.tinvwl_add_to_wishlist_button.disabled-add-wishlist{opacity:.5 !important;cursor:not-allowed}
.empty-name-wishlist{border-color:#f00 !important}
.tinvwl_remove_from_wishlist-text,.tinvwl_already_on_wishlist-text,.tinvwl_add_to_wishlist_button.tinvwl-icon-custom img.icon-already-on-wishlist{display:none !important}
.tinvwl-product-in-list.tinvwl-product-make-remove .tinvwl_remove_from_wishlist-text,.tinvwl-product-in-list.tinvwl-product-make-remove .tinvwl_already_on_wishlist-text,.tinvwl-product-in-list.tinvwl-product-make-remove img.icon-already-on-wishlist,.tinvwl-product-in-list.tinvwl-product-already-on-wishlist .tinvwl_remove_from_wishlist-text,.tinvwl-product-in-list.tinvwl-product-already-on-wishlist .tinvwl_already_on_wishlist-text,.tinvwl-product-in-list.tinvwl-product-already-on-wishlist img.icon-already-on-wishlist{display:inline !important}
.tinvwl-product-in-list.tinvwl-product-make-remove .tinvwl_add_to_wishlist-text,.tinvwl-product-in-list.tinvwl-product-make-remove img.icon-add-on-wishlist,.tinvwl-product-in-list.tinvwl-product-already-on-wishlist .tinvwl_add_to_wishlist-text,.tinvwl-product-in-list.tinvwl-product-already-on-wishlist img.icon-add-on-wishlist{display:none !important}
.tooltipped{position:relative}
.tooltipped::after{position:absolute;z-index:1000000;display:none;padding:.5em .75em;font:normal normal 11px/1.5 -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";-webkit-font-smoothing:subpixel-antialiased;color:#fff;text-align:center;text-decoration:none;text-shadow:none;text-transform:none;letter-spacing:normal;word-wrap:break-word;white-space:pre;pointer-events:none;content:attr(aria-label);background:#1b1f23;border-radius:3px;opacity:0}
.tooltipped::before{position:absolute;z-index:1000001;display:none;width:0;height:0;color:#1b1f23;pointer-events:none;content:"";border:6px solid transparent;opacity:0}
.tooltipped:hover::before,.tooltipped:hover::after,.tooltipped:active::before,.tooltipped:active::after,.tooltipped:focus::before,.tooltipped:focus::after{display:inline-block;text-decoration:none;-webkit-animation-name:tooltip-appear;animation-name:tooltip-appear;-webkit-animation-duration:.1s;animation-duration:.1s;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in;-webkit-animation-delay:.4s;animation-delay:.4s}
.tooltipped-no-delay:hover::before,.tooltipped-no-delay:hover::after,.tooltipped-no-delay:active::before,.tooltipped-no-delay:active::after,.tooltipped-no-delay:focus::before,.tooltipped-no-delay:focus::after{-webkit-animation-delay:0s;animation-delay:0s}
.tooltipped-multiline:hover::after,.tooltipped-multiline:active::after,.tooltipped-multiline:focus::after{display:table-cell}
.tooltipped-s::after,.tooltipped-se::after,.tooltipped-sw::after{top:100%;right:50%;margin-top:6px}
.tooltipped-s::before,.tooltipped-se::before,.tooltipped-sw::before{top:auto;right:50%;bottom:-7px;margin-right:-6px;border-bottom-color:#1b1f23}
.tooltipped-se::after{right:auto;left:50%;margin-left:-16px}
.tooltipped-sw::after{margin-right:-16px}
.tooltipped-n::after,.tooltipped-ne::after,.tooltipped-nw::after{right:50%;bottom:100%;margin-bottom:6px}
.tooltipped-n::before,.tooltipped-ne::before,.tooltipped-nw::before{top:-7px;right:50%;bottom:auto;margin-right:-6px;border-top-color:#1b1f23}
.tooltipped-ne::after{right:auto;left:50%;margin-left:-16px}
.tooltipped-nw::after{margin-right:-16px}
.tooltipped-s::after,.tooltipped-n::after{-webkit-transform:translateX(50%);transform:translateX(50%)}
.tooltipped-w::after{right:100%;bottom:50%;margin-right:6px;-webkit-transform:translateY(50%);transform:translateY(50%)}
.tooltipped-w::before{top:50%;bottom:50%;left:-7px;margin-top:-6px;border-left-color:#1b1f23}
.tooltipped-e::after{bottom:50%;left:100%;margin-left:6px;-webkit-transform:translateY(50%);transform:translateY(50%)}
.tooltipped-e::before{top:50%;right:-7px;bottom:50%;margin-top:-6px;border-right-color:#1b1f23}
.tooltipped-align-right-1::after,.tooltipped-align-right-2::after{right:0;margin-right:0}
.tooltipped-align-right-1::before{right:10px}
.tooltipped-align-right-2::before{right:15px}
.tooltipped-align-left-1::after,.tooltipped-align-left-2::after{left:0;margin-left:0}
.tooltipped-align-left-1::before{left:5px}
.tooltipped-align-left-2::before{left:10px}
.tooltipped-multiline::after{width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:250px;word-wrap:break-word;white-space:pre-line;border-collapse:separate}
.tooltipped-multiline.tooltipped-s::after,.tooltipped-multiline.tooltipped-n::after{right:auto;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}
.tooltipped-multiline.tooltipped-w::after,.tooltipped-multiline.tooltipped-e::after{right:100%}
.tooltipped-sticky::before,.tooltipped-sticky::after{display:inline-block}
.tooltipped-sticky.tooltipped-multiline::after{display:table-cell}
.tinvwl-table-manage-list .component_table_item_price:before{font-family:"FontAwesomeCP";font-size:1rem;display:inline-block;-ms-filter:"progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";-webkit-transform:rotate(90deg);transform:rotate(90deg);content:"\e811";margin:0 12px 0 3px;opacity:.25}
.wishlist-icon{position:relative}
.wishlist-popup{opacity:0;max-height:0;position:absolute;overflow:hidden;padding:5px;margin-top:-10px;border-radius:5px;line-height:1.3;text-align:center;font-size:.9em;top:100%;background-color:rgba(0,0,0,0.8);right:0;color:#FFF;-webkit-transition:opacity .3s,max-height .3s;transition:opacity .3s,max-height .3s;-webkit-transition-delay:.3s;transition-delay:.3s}
.wishlist-popup:after{bottom:100%;right:10px;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none;border-color:rgba(136,183,213,0);border-bottom-color:rgba(0,0,0,0.8);border-width:10px;margin-left:-10px}
.wishlist-icon:hover .wishlist-popup{opacity:1;max-height:200px;overflow-y:auto}
.wishlist-popup a{color:#ccc;display:block}
.wishlist-popup a:hover{color:#FFF}
.wishlist-icon.added:after{background-color:red;-webkit-transform:translateY(-3px);transform:translateY(-3px)}
.wishlist-popup .ajax-loading,.wishlist-popup .feedback{display:none !important}
.wishlist-title{margin-bottom:20px}
span.tinvwl-product-stats{background-color:#ebe9eb;border-radius:50%;padding:.2em;line-height:1;font-size:.7em;min-width:1.5em;display:inline-block;position:absolute;top:-0.7em;right:-1.5em;text-align:center}
@media screen and (min-width:0 \0){.tooltipped-multiline::after{width:250px}
}@media only screen and (max-width:1024px) and (min-width:769px){.tinv-wishlist .tinvwl-table-manage-list td.product-stock p{display:block;text-align:center}
.tinv-wishlist .product-stock span{display:block}
.tinv-wishlist .tinvwl-table-manage-list td.product-stock i{margin-right:0}
.tinv-wishlist .tinvwl-table-manage-list td.product-stock .tinvwl-txt{display:none}
.tinv-wishlist .product-thumbnail{max-width:76px;width:76px}
.tinv-wishlist .product-action .button>i{display:inline-block;margin-right:0}
.tinv-wishlist .product-action .button .tinvwl-txt{display:none}
}@media only screen and (max-width:1024px) and (min-width:768px){.tinv-wishlist .wishlist-action button[value=manage_remove] span{display:none}
.tinv-wishlist .wishlist-action button[value=manage_remove]>i{display:inline-block}
.tinv-wishlist tfoot .tinvwl-to-left+.tinvwl-to-right{margin-top:0}
}@media only screen and (max-width:1024px){.tinv-wishlist .tinvwl-table-manage-lists:not(.tinvwl-public){margin-top:0}
.tinv-wishlist .tinvwl-table-manage-list .product-remove{display:none}
.tinv-wishlist .tinvwl-table-manage-list .product-action button[name=tinvwl-remove]{display:inline-block;margin-top:5px}
.tinv-wishlist .product-action{width:60px}
.tinv-wishlist .wishlist-name .tinvwl-rename-button>i,.tinv-wishlist tfoot .tinvwl-to-left:not(:empty){margin-right:0}
.tinv-wishlist .wishlist-name .tinvwl-rename-button span{display:none}
.tinv-wishlist .wishlist-action{width:60px}
.tinv-wishlist tfoot .tinvwl-to-left:not(:empty){float:none;width:auto}
.tinv-wishlist tfoot .tinvwl-to-right{float:none;width:auto;text-align:left}
.tinv-wishlist .social-buttons{float:none;width:auto;text-align:left;margin-top:20px}
.tinv-wishlist .social-buttons+.tinv-wishlist-clear+.navigation-button{margin-top:30px}
.tinv-wishlist .tinv-login form input[name=login]{min-width:auto}
.tinv-wishlist .tinvwl-table-manage-list .product-remove{display:table-cell}
}@media only screen and (max-width:1023px){.tinv-wishlist .tinv-lists-nav a.tinv-prev>i,.tinv-wishlist .tinv-lists-nav a.tinv-next>i{margin:0}
.tinv-wishlist .tinv-lists-nav a.tinv-prev>span,.tinv-wishlist .tinv-lists-nav a.tinv-next>span{display:none}
}@media only screen and (max-width:768px){.tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove,.tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name){display:none}
.tinv-wishlist table.tinvwl-table-manage-lists thead th:not(.wishlist-name){display:none}
.tinv-wishlist thead th .tinvwl-full{display:none}
.tinv-wishlist table.tinvwl-table-manage-list thead th.product-name,.tinv-wishlist table.tinvwl-table-manage-lists thead th.wishlist-name{display:block;width:100%;text-align:center}
.tinv-wishlist table thead th .tinvwl-mobile{display:block}
.tinv-wishlist table.tinvwl-table-manage-list tbody td{display:block;width:100% !important;text-align:center}
.tinv-wishlist table.tinvwl-table-manage-lists tbody td,.tinv-wishlist table.tinvwl-table-manage-lists.tinvwl-public tbody td{display:block;width:100% !important;text-align:center}
.tinv-wishlist table.tinvwl-table-manage-list tbody td:not(:last-child){border-bottom:0}
.tinv-wishlist table.tinvwl-table-manage-lists tbody td:not(:last-child),.tinv-wishlist table.tinvwl-table-manage-lists.tinvwl-public tbody td:not(:last-child){border-bottom:0}
.tinv-wishlist .product-stock p{margin:0 auto}
.tinv-wishlist .product-thumbnail img{margin:0 auto;max-width:80px}
.tinv-wishlist.woocommerce table .quantity .qty{text-align:center;width:100%}
.tinv-wishlist .product-action .tinvwl_move_product_button{margin-top:10px}
.tinv-wishlist table.tinvwl-table-manage-list tfoot td{display:block;width:100%}
.tinv-wishlist table.tinvwl-table-manage-lists .wishlist-action button[value=manage_remove]{width:100%}
.tinv-wishlist table.tinvwl-table-manage-lists .wishlist-name .tinvwl-rename-button{float:none}
.tinv-wishlist button[value=manage_apply] .tinvwl-mobile,.tinv-wishlist button[value=product_apply] .tinvwl-mobile{display:none}
.tinv-wishlist tfoot .tinvwl-to-right .tinv-create-list{display:block}
.tinv-wishlist .tinvwl-table-manage-list tfoot .tinvwl-to-right .button,.tinv-wishlist .tinvwl-table-manage-list tfoot .tinvwl-to-right button{width:100%;margin:10px 0 0}
.tinv-wishlist .tinvwl-table-manage-lists tfoot .tinvwl-to-right .button,.tinv-wishlist .tinvwl-table-manage-lists tfoot .tinvwl-to-right button{width:100%;margin:10px 0 0}
.tinv-wishlist tfoot .tinvwl-to-right>*{margin:10px 0 0}
.tinv-wishlist tfoot .tinvwl-to-right .button:first-child,.tinv-wishlist tfoot .tinvwl-to-right button:first-child{margin-top:0 !important}
.tinv-wishlist tfoot .tinvwl-to-left+.tinvwl-to-right{margin-top:20px}
.tinv-wishlist .social-buttons{text-align:center}
.tinv-wishlist .social-buttons>span{display:block;margin-top:0;margin-right:0;margin-bottom:5px}
.tinv-wishlist.woocommerce .tinv-login form .form-row-first,.tinv-wishlist.woocommerce .tinv-login form .form-row-last{float:none;width:100%}
.tinv-wishlist.woocommerce .tinv-login form .form-row-first{padding:0}
.tinv-wishlist.woocommerce .tinv-login form .form-row-last{padding:0;margin-top:10px}
.tinv-wishlist.woocommerce .tinv-login form .tinvwl-input-group-btn{display:block;padding:0;width:auto;margin-top:10px}
.navigation-button,.social-buttons,.tinv-lists-nav{margin-left:12px;margin-right:12px}
.tinvwl-input-group{width:100%}
.tinvwl-input-group:not(.tinvwl-no-full){display:block}
.tinvwl-input-group:not(.tinvwl-no-full) .form-control,.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-addon,.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-btn{display:block}
.tinvwl-input-group:not(.tinvwl-no-full) .form-control{float:none}
.tinv-wishlist .tinv-search-form .tinvwl-input-group:not(.tinvwl-no-full) .form-control+.tinvwl-input-group-btn{padding-top:10px;padding-left:0}
.tinvwl-input-group:not(.tinvwl-no-full) .form-control+.tinvwl-input-group-btn{padding-top:10px;padding-left:0}
.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-addon{width:100%}
.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-addon>button,.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-addon>input{width:100%}
.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-btn{width:100%}
.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-btn>button,.tinvwl-input-group:not(.tinvwl-no-full) .tinvwl-input-group-btn>input{width:100%}
.tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove{display:block}
}@media screen and (max-width:768px){.admin-bar .tinv-wishlist .tinv-modal{padding-top:46px !important}
}@media screen and (max-width:600px){.admin-bar .tinv-wishlist .tinv-modal{padding-top:0 !important}
.tinv-wishlist .tinv-modal .tinv-close-modal{position:static;display:block;margin:0 auto 20px}
}@-webkit-keyframes tooltip-appear{from{opacity:0}
to{opacity:1}
}@keyframes tooltip-appear{from{opacity:0}
to{opacity:1}
}